.first-card-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 15px;
	
	flex-wrap: wrap;
	background-size: cover;
	background-repeat: no-repeat;

	background-image: url('../../../../public/firstcardimage.png');
}

