.card-mini-container {
	margin-bottom: 20px;
	display: flex;
	color: black;
	margin: 20px 20px 20px 20px;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	max-width: 624px;
	margin-left: 180px;
	font-family: 'Questrial';
	line-height: 1.5;
}
.card-mini-title {
	margin-top: 40px;
	font-size: 48px;
	font-weight: 400;
	line-height: 125%;
	
	font-family: 'Questrial';
}
.card-mini-subtitle {
	line-height: 1.5;
	font-size: 18px;
	font-weight: 400;
	font-family: 'Questrial';
	display: flex;
	align-items: flex-start;
	text-align: start;
}
@media screen and (max-width: 780px) {
	.card-mini-container {
		margin-left: 10px;
		margin-right: 10px;
	}
}
