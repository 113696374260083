.upvcc-imgcont {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
.upvcc-imgcont img {
  width: 603px;
  height: 377px;
  max-height: 477px;
  box-shadow: 5px 5px 5px #ccc;
  /* border: 1px solid gray; */
  -moz-box-shadow: 5px 5px 5px #ccc;
  -webkit-box-shadow: 5px 5px 5px #ccc;
  -khtml-box-shadow: 5px 5px 5px #ccc;
}
.upvcc-imgcont img:hover {
  max-height: 477px;
  box-shadow: 10px 10px 5px #ccc;
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  -khtml-box-shadow: 10px 10px 5px #ccc;
}
.testdiv {
  margin-left: 70px;
  text-align: justify;
}
.testdiv2 {
  margin-right: 70px;
}
.upvcc-container {
  margin: 80px 160px 140px 160px;
  /* max-width: 1412px; */
  text-align: justify;
  line-height: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}
.last-kyc-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1400px) {
  .upvcc-container {
    margin: 40px 40px 40px 80px;
    /* max-width: 1012px; */
    line-height: 2rem;
    flex-wrap: nowrap;
  }
  .upvcc-imgcont img {
    width: 403px;
    height: 377px;
    max-height: 477px;
  }
}
@media screen and (max-width: 800px) {
  .upvcc-container {
    margin: 20px 20px 20px 20px;
    /* max-width: 1012px; */
    line-height: 2rem;
    flex-wrap: wrap;
  }
  .testdiv {
    margin-left: 10px;
  }
  .testdiv2 {
    margin-right: 10px;
  }

  .upvcc-imgcont img {
    width: 303px;
    height: 477px;
    max-height: 477px;
    box-shadow: 5px 5px 5px #ccc;
    -moz-box-shadow: 5px 5px 5px #ccc;
    -webkit-box-shadow: 5px 5px 5px #ccc;
    -khtml-box-shadow: 5px 5px 5px #ccc;
  }
}
