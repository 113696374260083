.card-right-image {
	width: 100%;
	height: 100%;
}
.card-right-image-container {
	margin-bottom: 20px;
	height: 100%;
	min-height: 409px;
}
@media screen and (max-width: 480px) {
	.card-right-image-container {
		max-height: 609px;
	}
}
