.footer-title {
  line-height: 1.2;

  font-weight: bold;
  font-size: 34px;
  color: black;
  margin-left: 20%;
  margin-top: 80px;
}
.navbar-fot-image {
  width: 200px;
  height: 75px;
}
.footer-container {
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 40px;

  /* background-image: url('../../../public/contact.png'); */
}
a {
  text-decoration: none !important;
}
.footer-subtitle {
  font-size: 20px;
  font-weight: 400;
}
.footer-solid {
  border: 2px solid #0085f0;
  width: 1248px;
}
@media screen and (max-width: 480px) {
  .footer-container {
    margin-right: 0px;
  }
  .footer-solid {
    border: 2px solid #0085f0;
    width: 343px;
  }
  .footer-title {
    margin-left: 5px;
  }
}
