.new-account-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.new-account-container {
  padding: 40px 140px 40px 140px;
}
.new-account-p {
  font-size: 20px;
  line-height: 135%;
  text-align: center;
  color: #f22aa2;
  margin-bottom: 40px;
}
.new-account-h {
  font-size: 39px;
  line-height: 46px;
  text-align: center;
}
.modal-title-acc {
  /* background-size: cover;
  background-repeat: no-repeat;

  background-image: url("../../../public/newcon.png"); */
  padding-left: 150px;
}

.new-account-card-button {
  font-weight: 400;
  margin-top: 40px;
  border-radius: 6px;
  border: none !important;
  background-color: #f22aa2 !important;
}
.new-account-card-modal-button {
  font-weight: 400;
  margin-left: 260px;
  border-radius: 6px;
  border: none !important;
  background-color: #f22aa2 !important;
}
@media screen and (max-width: 780px) {
  .new-card-demo-button {
    width: 100%;
  }
  .diag-img {
    max-width: 400px;
    min-height: 400px;
  }
  .new-contact-form-container {
    margin-left: 0px;
  }
  .new-account-container {
    padding: 10px 10px 10px 20px;
  }
  .modal-title-acc {
    /* background-size: cover;
    background-repeat: no-repeat;
  
    background-image: url("../../../public/newcon.png"); */
    padding-left: 80px;
  }
  .new-account-card-modal-button {
    margin-left: 140px;
  }
  .new-account-h {
    font-size: 19px;
  }
}
