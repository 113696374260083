.main-card-container {
  max-height: 376px;
  max-width: 450px;
  color: black;
  margin-bottom: 80px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Questrial";
}
.mini-card-subHeader {
  color: black;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 400;
}
.mini-card-header {
  font-size: 24px;
  font-weight: 600;
  margin-top: 15px;
  color: #36d9d9;
}
.main-card-img {
  max-width: 300px;
  max-height: 157px;
}
