.form-control {
  min-width: 450px;
  background-color: white;
  color: black;
}
.form-control:focus {
  /* min-width: 496px; */
  min-width: 450px;
  background-color: white;
  color: black;
}
.form-control:hover {
  /* min-width: 496px; */
  min-width: 450px;
  background-color: white;
  color: black;
}

.contact-us-button {
  font-weight: 400;
  width: 143px;
  border-radius: 0px;
  border: none;
  background-color: #f22aa2;
  font-size: 18px;
  margin-top: 20px;
}
@media screen and (max-width: 480px) {
  .contact-us-button {
    width: 100%;
  }
  .form-control {
    min-width: fit-content;
  }
  .form-control:focus {
    min-width: fit-content;
  }
  .form-control:hover {
    min-width: "fit-content";
  }
}
