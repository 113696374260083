.third-card-container {
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	text-align: center;
	font-family: 'Questrial';

	flex-wrap: wrap;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url('../../../../public/newmiss.png');
	color: #ffffff;
}
.solid {
	border: 1px solid #36d9d9;
	max-width: 550px;
	margin-left: 180px;
}
@media screen and (max-width: 480px) {
	.solid {
		margin-left: 10px;
		margin-right: 10px;
	}
}
