body {
	margin: 0;
	background-color: #ffffff;
	font-family: 'Questrial';
}

@media screen and (max-width: 480px) {
	body {
		margin-left: 10px;
		margin-right: 10px;
	}
}
