.why-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.why-item {
  display: flex;
  width: 600px;
}
.f-title {
  margin: 20px;
  font-size: 16px;
  line-height: 135%;
  font-weight: 700;
}
.why-title {
  font-size: 40px;
  font-weight: 800;
  margin-left: 40%;
  margin-bottom: 40px;
}
