.card-image {
	max-width: 764px;
	max-height: 577px;
}
.card-image-container {
	margin-bottom: 20px;
	height: 100%;
	min-height: 409px;
}
@media screen and (max-width: 480px) {
	.card-image-container {
		max-height: 609px;
		max-width: 400px;
	}
	.card-image {
		max-width: 300px;
		
	}
}
