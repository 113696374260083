.contact-title {
	line-height: 1.2;

	margin-bottom: 0.75rem;
	margin-top: 80px;
	font-weight: bold;
	font-size: 34px;
}
.contact-form-container {
	margin-left: 660px;
}

.contact-container {
	display: flex;
	width: 100%;
	min-height: 730px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	color: black;
	background-size: cover;
	background-repeat: no-repeat;

	background-image: url('../../../public/newcon.png');
}
.contact-subtitle {
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 0.75rem;
}
@media screen and (max-width: 1280px) {
	.card-demo-button {
		width: 100%;
	}
	.contact-form-container {
		margin-left: 0px;
	}
}
