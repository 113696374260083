.nav-container {
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 10%;
	display: flex;
	justify-content: center;
	align-items: center;
	

}

.navbar-logo {
	margin-left: 160px;
	
}
.navbar-logo-image{
	width: 180px;
	height: 75px;
}
.nav-link {
	margin-left: 50px;
	color: #36d9d9 !important;
}
.nav-link:hover {
	border-bottom: 3px solid #f22aa2;
	/* border-radius: 4px; */
	color: #36d9d9;
	font-weight: bold;
	/* transition: all 0.2s ease-out; */
  }



.nav-links {
	display: flex;
	align-items: center;


}
.a-con{
	padding: 0px 20px;
}
@media screen and (max-width: 1200px) {
	.nav-container {
		margin-left: 10px;
	
		margin-right: 10px;
	}
	.a-con{
		padding: 40px 0px;
	}
	.nav-links {
		line-height: 2.5rem;
		font-weight: 800;
		margin-right: 0px;
		margin-left: 0px;
	}
	.nav-sticky {
		margin-left: 0px;
		margin-right: 0px;
	}
	.navbar-logo {
		margin-left: 0px;
	}
	.nav-link {
		margin-left: 0px;
	}
}
@media screen and (max-width: 768px) {
	.nav-container {
		
		margin-bottom: 28%;
		
		
	
	}
	.nav-link {
		margin-top: 60px;
		text-align: center;
	}
	.nav-button{
		
		text-align: center;
		margin-left: 100px;
		
	}

	
}
.nav-button {
	height: 48px;
	width: 143px;
	font-weight: 400;
	margin-right: 250px;
	background-color: #f22aa2 !important;
	color: #fff !important;

}

.navbar-logo {
	font-weight: bold;
	font-size: 1.5rem;
}
