.service-container {
	display: flex;
	justify-content: space-around;

	margin-top: 50px;
	margin-bottom: 30px;
}
.service-header {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
	font-size: 48px;
	font-weight: 400;
}
@media screen and (max-width: 480px) {
	.service-container {
		flex-direction: column-reverse;
	}
}
