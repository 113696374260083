.card-title {
  line-height: 1.2;
  color: #36D9D9;
  margin-bottom: 0.75rem;
  font-weight: bold;
  font-size: 54px;
}
.card-mini-title {
  margin-bottom: 0.75rem;
  font-weight: bold;
  font-size: 40px;
}
.card-container {
  max-width: 480px;
  display: flex;
  flex-direction: column;
  margin: 40px 40px 40px 40px;
  line-height: 1.5rem;
  margin-top: 40px;
}
.card-subtitle {
  margin-bottom: 0.75rem;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 400;
  color: #67718d;
}
.card-mini-title{
  margin-bottom: 0.75rem;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 700;
  color: black;
}
.card-button {
  font-weight: 400;
  width: 143px;
  border-radius: 0px;
  border: none !important;
  background-color: #f22aa2 !important;
  font-size: 18px;
  margin-top: 20px;
}
@media screen and (max-width: 480px) {
  .card-button {
    width: 100%;
  }
}
