.account-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.account-container {
  padding: 40px 140px 40px 140px;
  background-color: #f6f6f6;
}
.account-p {
  font-size: 40px;
  line-height: 135%;
  text-align: center;
  color: #f22aa2;
  margin-bottom: 40px;
}
.account-h {
  font-size: 19px;
  line-height: 46px;
  text-align: center;
}
.kyc-button {
  font-weight: 400;

  border-radius: 8px;
  border: none !important;
  background-color: #f22aa2 !important;
}
@media screen and (max-width: 780px) {
  .new-card-demo-button {
    width: 100%;
  }
  .diag-img {
    max-width: 400px;
    min-height: 400px;
  }
  .new-contact-form-container {
    margin-left: 0px;
  }
  .account-container {
    padding: 10px 10px 10px 20px;
  }
}
