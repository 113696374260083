.card-left-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 20px;
	max-width: 600px;
	margin: 20px;
}
.card-left-subheader {
	line-height: 1.5;
	font-size: 18px;
	font-weight: 400;
	color: #67718d;
}
.card-left-header {
	margin-bottom: 20px;
	color: #36d9d9;
	font-size: 30px;
	font-weight: 400;
	line-height: 125%;
}
.left-list {
	line-height: 1.5;
	font-size: 18px;
	font-weight: 400;
	color: #67718d;
}
.left-list ::marker {
	color: #36d9d9;
}
