.career-container-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  padding: 0 20px;
  text-align: center;
}
.career-h {
  font-size: 4rem;
  color: #f22aa2;
}
