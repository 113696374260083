.card-demo-title {
	line-height: 1.2;

	margin-bottom: 0.75rem;
	font-weight: bold;
	font-size: 34px;
}
.my-hero {
	
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 411px;
	  background-color: rgba(211,211,211, 0.65);
	  
	  
	  /* transition: background 0.3s, border-radius 0.3s, opacity 0.3s; */
	  
	
	
  }

.card-demo-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 411px;

	color: black;
	background-size: cover;
	background-repeat: no-repeat;

	background-image: url('../../../public/newbill.png');
}
.card-demo-subtitle {
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 0.75rem;
}
.card-demo-button {
	font-weight: 400;
	width: 143px;
	border-radius: 0px;
	background-color: #f22aa2 !important;
	font-size: 18px;
	margin-top: 20px;
}
@media screen and (max-width: 480px) {
	.card-demo-button {
		max-width: 328px;
	}
}
