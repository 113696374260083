.group-container {
	display: flex;
	flex-direction: column;

	background-size: cover;
	background-repeat: no-repeat;
	/* background-image: radial-gradient(ellipse closest-side at 50% 50%, #144275, #172650 80%); */
}
.group-card-container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	background-size: cover;
	background-repeat: no-repeat;
	/* background-image: radial-gradient(ellipse closest-side at 50% 50%, #144275, #172650 80%); */
}
