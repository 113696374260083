.main-card-title-container {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.heading-1 {
  font-family: "Jost";
  color: #36d9d9;
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 24px;
}
.heading-2 {
  color: #36d9d9;
  margin-bottom: 4px;
  font-size: 24px;
}
.heading-3 {
  color: black;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  line-height: 135%;
  margin-bottom: 64px;
}
