.second-card-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	flex-wrap: wrap;
	background-size: cover;
	background-repeat: no-repeat;
	margin-top: 50px;
	margin-bottom: 50px;
}
.card-second-title {
	line-height: 1.2;
	color: #212529;
	margin-bottom: 0.75rem;
	font-weight: bold;
	font-size: 54px;
}

.card-second-container {
	max-width: 624px;
	display: flex;
	flex-direction: column;
	margin: 40px 40px 40px 40px;
	line-height: 1.5rem;
	margin-top: 40px;
}
.card-second-subtitle {
	margin-bottom: 0.75rem;
	line-height: 1.5;
	font-size: 18px;
	font-weight: 400;
	color: #67718d;
}
@media screen and (max-width: 480px) {
	.second-card-container {
		flex-direction: column-reverse;
	}
}
